#antwort {
    padding: 0 20px 80px 20px;
    overflow: hidden;
    form {
        position: relative;
        width: 360px;
        max-width: 100%;
        margin: 0 auto;
        #formBG {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 200%;
            transform: translate(-50%,-50%); }

        .formContent {
            transform: rotate(-3deg);
            padding: 20px;
            background: url(../img/bg_pattern.jpg) center repeat;
            background-size: 220px;
            border-radius: 4px;
            position: relative;
            text-align: left;
            box-shadow: 0 0 5px rgba($base-font-color, 0.2),0 0 20px rgba($base-font-color, 0.2);
            margin: 0 auto;
            z-index: 1;
            &:after {
                content: "";
                width: 80px;
                height: 80px;
                background: url(../img/stern.png) right top no-repeat;
                background-size: contain;
                position: absolute;
                right: 10px;
                top: 10px; }

            h3 {
                font-size: 1.8rem;
                color: $main-color;
                line-height: 1.2;
                font-family: $heading;
                font-weight: normal;
                margin: 0; }
            p {
                margin-top: 10px;
                margin-bottom: 30px;
                color: $base-font-color;
                font-size: 1.2rem; }
            input[type="text"], input[type="email"] {
                all: unset;
                width: 100%;
                padding: 5px 10px;
                background: #fff;
                box-sizing: border-box;
                box-shadow: inset 1px 1px 6px rgba(black, 0.2);
                color: #69b0c4;
                margin-bottom: 15px;
                &::placeholder {
                    font-size: 1rem;
                    color: #69b0c4; } }
            input[type="checkbox"] {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top; }
            label {
                display: inline-block;
                width: 90%;
                // margin-bottom: 50px
                p {
                    display: inline-block;
                    font-size: 0.925rem;
                    line-height: 1.2;
                    color: #69b0c4;
                    margin: 0; } }
            input#submit {
                all: unset;
                left: -20px;
                background: url(../img/gold.png) left -10px center no-repeat;
                background-size: contain;
                position: relative;
                margin-top: 3rem;
                width: 188px;
                height: 44px;
                color: white;
                padding-left: 30px;
                box-sizing: border-box;
                font: 400 1.6rem $heading!important;
                text-shadow: 0 0 4px rgba(black, 0.5);
                cursor: pointer;
                display: inline-block;
                letter-spacing: 0.05rem;
                line-height: 30px!important;
                outline: none;
                text-align: left;
                border: none; }
            #schlitten {
                width: 110px;
                position: absolute;
                right: 20px;
                bottom: 20px; } } } }

.message {
    //margin: 1rem
    // padding: 0.5rem 1rem
    color: white;
    background: #75c475;
    background: url('../img/bg_1.jpg') center;
    background-size: cover;
    position: fixed;
    width: 90%;
    max-width: 600px;
    padding: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999999;
    &.success {
        box-shadow: 0 0 5000px 5000px rgba($base-font-color, 0.95);
        h2 {
            color: $base-font-color;
            line-height: 1.2;
            font-size: 2rem; } }
    &.error {
        box-shadow: 0 0 5000px 5000px rgba($main-color, 0.95);
        h2 {
            color: $main-color;
            line-height: 1.2;
            font-size: 2rem; } }
    a {
        color: $main-color;
        font-size: 1.2rem; }
    img {
        max-width: 100%; } }
