header {
    width: 100%;
    margin-bottom: -10px;
    background-color: #e9f8ff;
    background: url(../img/schnee_vorne.png) 0% 0% repeat, url(../img/schnee.png) left 0% repeat, url(../img/schnee_hinten.png) left 0% repeat, url(../img/berge_hg.jpg) bottom center;
    animation: schnee 10000ms infinite linear;
    background-size: 700px 350px, 700px 350px, 700px 350px, cover;
    overflow: hidden;
    z-index: 0;
    .spacer {
        width: 100vw;
        height: 5vw;
        @media (max-width: 1270px) {
            height: 150px; } }
    nav {
        width: calc(100% - 280px);
        position: fixed;
        right: 40px;
        top: 30px;
        text-align: right;
        z-index: 1;
        transition: all 500ms cubic-bezier(0.365, -0.510, 0.835, 0.650);
        @media (max-width: 350px) {
            width: 42%;
            position: fixed;
            right: 40px;
            top: 30px;
            text-align: right; }

        a {
            font-size: 0.925rem;
            color: white;
            display: inline-block;
            padding-left: 20px;
            padding-right: 0;
            transition: all 200ms ease;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.1rem;
            &:hover {
                transform: translate(0px, 0px) rotate(-3deg);
                color: white; } }
        &.nav-out {
            right: -600px;
            a {
                padding: 0; } } }
    #logo {
        position: absolute;
        margin-left: 20px;
        transform-origin: 50% 0%;
        transform: rotate(-3deg);
        display: inline-block;
        padding-top: 50px;
        animation: shield 2500ms ease-in-out infinite;
        z-index: 999999;
        @media (max-width: 375px) {
            margin-left: 0px;
            padding-top: 30px; }
        .shieldOuter {
            width: 200px;
            height: 90px;
            background: #512f13;
            border-radius: 4px;
            position: relative;
            padding: 5px;
            @media (max-width: 350px) {
                width: 150px;
                height: 60px; }
            &:before {
                content: "";
                width: 5px;
                height: 100px;
                position: absolute;
                top: -100%;
                left: 40px;
                background: repeating-linear-gradient(-20deg, #382e28, #382e28 1px, rgba(black, 0) 1px, rgba(black, 0) 5px), linear-gradient(to right, #a88981 0%, #cfbcb1 30%, #554843 100%);
                z-index: -1; }
            &:after {
                content: "";
                width: 5px;
                height: 100px;
                position: absolute;
                top: -100%;
                right: 40px;
                background: repeating-linear-gradient(-20deg, #382e28, #382e28 1px, rgba(black, 0) 1px, rgba(black, 0) 5px), linear-gradient(to right, #a88981 0%, #cfbcb1 30%, #554843 100%);
                z-index: -1; }
            .shieldInner {
                background: #fff;
                width: 100%;
                height: 100%;
                border-radius: 2px;
                position: relative;
                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    max-width: 80%;
                    max-height: 80%;
                    transform: translate(-50%,-50%);
                    width: 100%; } } } }

    .content {
        max-width: 650px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        background: url(../img/bg_pattern.jpg) center repeat;
        background-size: 300px;
        position: relative;
        z-index: 0;
        box-shadow: 0 0 5px rgba($base-font-color, 0.2),0 0 20px rgba($base-font-color, 0.2);
        transform: rotate(-2deg);
        border: 10px solid transparent;
        padding: 15px;
        border-image: url(../img/wood.jpg) 20% round;
        z-index: 2;
        &:before {
            content: "";
            width: 20px;
            height: 500px;
            position: absolute;
            top: 0;
            left: 40px;
            background-color: #422c00;
            background-image: url("../img/wood.jpg");
            z-index: -1;
            top: 100%; }
        &:after {
            content: "";
            width: 20px;
            height: 500px;
            position: absolute;
            top: 0;
            right: 40px;
            background-color: #422c00;
            background-image: url("../img/wood.jpg");
            z-index: -1;
            top: 100%; }
        .schneedecke {
            position: absolute;
            height: 100px;
            width: 115%;
            background-size: contain;
            top: -41px;
            left: -7%; }
        .contentSchild {
            display: flex;
            flex-wrap: wrap;
            margin: 20px 10px 0;
            width: 100%;
            &.datenschutz {
                margin: 20px auto; }
            @media (max-width: 624px) {
                margin: -10px 0 0; }
            .text {
                flex: 2 0 0;
                min-width: 200px; }
            h2 {
                font-family: $heading;
                font-weight: normal;
                color: $main-color;
                margin: 0 auto;
                text-align: center; }
            .stoerer {
                flex: 1 0 0;
                background-image: url("../img/Geschenk.png");
                height: 160px;
                background-size: contain;
                background-repeat: no-repeat;
                cursor: pointer;
                min-width: 200px;
                text-align: center;
                background-position: center center;
                top: -40px;
                right: 13px;
                p {
                    bottom: -148px;
                    left: -17px; }
                @media (max-width: 624px) {
                    top: -20px;
                    p {
                        text-align: center; } } } }

        p {
            color: $base-font-color;
            font-size: 1.2rem;
            font-weight: 400; }
        a {
            font-size: 1.2rem; } }
    .beispielpreise {
        cursor: pointer;
        img {
            width: 100%;
            max-width: 350px;
            margin: -30px 0 0 0; }
        h3 {
            font-family: "Black And White Picture",sans-serif;
            font-size: 1.8rem;
            color: $base-font-color;
            line-height: 1.2;
            font-weight: 400;
            margin:  0 0 10px 40px; } }

    #woodBg {
        position: absolute;
        right: 0;
        bottom: 5vw;
        width: 50%;
        max-width: 600px;
        z-index: -1; }
    #snowBg {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1; }
    #snowFg {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2; }
    #adventskalender {
        width: 90%;
        max-width: 650px;
        height: 100px;
        margin: 0 auto 20px; } }
.downArrow {
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: $main-color;
    margin: 20px auto;
    cursor: pointer;
    position: relative;
    transition: all 300ms cubic-bezier(0.250, 0.100, 0.420, 2);
    top: 0;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -40%);
        width: 25px; }

    &:after {
        content: "";
        width: 90%;
        height: 90%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: all 300ms cubic-bezier(0.250, 0.100, 0.420, 1.2);
        border: 1px dashed white; }
    &:hover {
        top: 7px;
        &:after {
            transform: translate(-50%, -50%) rotate(25deg); } } }


@keyframes shield {
    0%, 100% {
        transform: rotate(-6deg); }
    50% {
        transform: rotate(-2deg); } }

@keyframes schnee {
    from {
        background-position: left 0%; }

    to {
        background-position: left 1400px, left 700px, left 350px, left 0%; } }
