#fragen {
    width: 100%;
    padding: 0 40px;
    background: url(../img/bg_pattern.jpg);
    background-size: 300px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 4vw;
    overflow: hidden;
    #kerze {
        width: 250px;
        margin-bottom: 2vw; }
    #snowman {
        background: url(..//img/schneemann_flagge.png) center no-repeat;
        background-size: contain;
        width: 300px;
        height: 350px;
        margin: 0 0 0 auto;
        position: relative;
        @media (max-width: 770px) {
            margin: 0 auto; }
        @media (max-width: 350px) {
            right: 10%; }

        #day {
            font-family: $heading;
            font-weight: normal;
            color: #ffcc00;
            font-size: 2rem;
            position: absolute;
            top: 35px;
            right: 48px;
            text-align: center;
            display: inline-block;
            width: 20px; } }
    .raetselImage {
        width: 500px;
        max-width: 100%;
        margin: 15px 0 30px 0;
        @media (max-width: 350px) {
            width: 200px; } }


    .col-3 {
        text-align: left;
        @media (max-width: 770px) {
            margin: 0 auto;
            display: inline-block; } }

    .col-2 {
        padding-right: 20px;
        @media (max-width: 770px) {
            padding-right: 0px; } }

    h2#quiz {
        color: $main-color;
        margin: 0 auto;
        display: inline;
        font-size: 3rem;
        font-family: $heading;
        font-weight: 400;
        position: relative;
        max-width: 90%;
        line-height: 1;
        &:after {
            content: "";
            width: 69px;
            height: 81px;
            background: url(../img/sterne_text.png) center no-repeat;
            background-size: contain;
            position: absolute;
            right: -75px;
            top: -15px; }
        @media (max-width: 650px) {
            &:after {
                content: none; } } }
    p {
        margin-top: 30px;
        font-size: 1.2rem;
        color: $base-font-color;
        display: block;
        max-width: 500px; }
    .soft-row {
        max-width: 1400px;
        width: 100%;
        align-items: center;
        @media (max-width: 770px) {
            display: block; } } }

