
@import url('https://fonts.googleapis.com/css?family=Black+And+White+Picture|EB+Garamond:400,400i,600');

* {
	font-family: $copy; }



a {
	color: $base-font-color;
	&:hover {
		color: $main-color; } }

h1, h2 {
	font-size: 3em;
	margin-bottom: 30px; }
h3 {
	font-size: 2.4em;
	margin-bottom: 30px; }
h4 {
	font-size: 1.8em;
	margin-bottom: 30px; }
h5 {
	font-size: 1.4em;
	margin-bottom: 30px; }



