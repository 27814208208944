.container {
    max-width: 1200px;
    margin: 0 auto; }
// body
//     background: url(../img/bg_pattern.jpg) center repeat
//     background-size: 300px
.row {
    padding: 30px;
    display: block;
    @media (max-width: 350px) {
        padding: 0 30px 30px; } }
.soft-row {
    display: flex;
    flex-wrap: wrap; }
.col-1 {
    flex: 1 0 0; }
.col-2 {
    flex: 2 0 0; }
.col-3 {
    flex: 3 0 0; }
.col-4 {
    flex: 4 0 0; }
.col-5 {
    flex: 5 0 0; }
.col-6 {
    flex: 6 0 0; }

hr {
    margin: 20px 0; }

.backgroundImage {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
section {
    background: #fff;
    // background: url(../img/bg_pattern.jpg) center repeat
 }    // background-size: 300px
