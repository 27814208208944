#preise {
    padding: 60px 10px;
    text-align: center;
    overflow: hidden;
    #geschenke {
        margin: 0 auto;
        max-width: 330px;
        display: block; }
    h2 {
        display: block;
        margin: 0 auto;
        color: $main-color;
        font-family: $heading;
        font-weight: normal; }
    .preise-grid {
        width: 100%;
        max-width: 1000px;
        margin: 20px auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;


        .calendarItem {
            perspective: 1000px;
            max-width: 100%;
            height: 220px;
            min-width: 200px;
            cursor: pointer;
            perspective: 2500px;
            perspective-origin: 30% 60%;
            .calendarFront {
                width: 100%;
                height: 100%;
                background: url(../img/tuerchen.jpg) center;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;
                transform-origin: 0% 0%;
                transition: all 300ms ease;
                z-index: 10;
                position: relative;




                h4 {
                    font-size: 3rem;
                    color: $main-color;
                    font-family: $heading;
                    font-weight: normal;
                    margin: 0;
                    line-height: 1; } }
            .calendarBack {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: url(../img/tuerchen_innen.jpg) center;
                background-size: cover;
                box-shadow: inset 0px 0px 20px rgba(black, 0.8);
                padding: 10px;
                border: 2px solid rgba(black, 0.8);
                border-bottom: 1px solid rgba(white, 0.8);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 100%;
                .calendarImg {
                    position: absolute;
                    width: 100%;
                    height: 80%;
                    top: 0;
                    left: 0;

                    img {
                        max-width: 80%;
                        max-height: 80%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        position: absolute; } }
                .calendarText {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    height: 30%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        color: white;
                        text-transform: uppercase;
                        letter-spacing: 0.1rem;
                        font-size: 0.825rem;
                        max-width: 80%;
                        @media (max-width: 350px) {
                            font-size: 0.65rem; } } } }


            &:not(.future) {
                &:hover, &:active {
                    .calendarFront {
                        transform: rotateY(-85deg); } } }
            &.past {
                pointer-events: none;
                .calendarFront {
                    transform: rotateY(-91deg);
 }                    // display: none
                .calendarBack {
                    .calendarImg, .calendarText {
                        opacity: 0.5; } }
                @media (max-width: 650px) {
                    display: none; } } }
        @media (min-width: 1400px) {
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 20px;
            max-width: 1400px; }
        @media (max-width: 960px) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px; }
        @media (max-width: 660px) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            .calendarItem {
                height: 200px;
                min-width: 100%; } }


        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
            .calendarItem {
                display: inline-block;
                width: 200px;
                height: 220px;
                margin: 10px;
                @media (max-width: 660px) {
                    height: 200px;
                    min-width: 180px; } } } } }
