* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    color: #333;
    position: relative;
    font-size: 16px; }
