#gewinner {
    padding: 80px 20px;
    .soft-row {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 770px) {
            display: block; }
        .col-2 {
            text-align: right;
            padding-right: 20px;
            @media (max-width: 770px) {
                text-align: center;
                padding-right: 0;
                margin: 0 auto; }
            #santa {
                width: 155px; } }
        h2 {
            color: $main-color;
            font-family: $heading;
            font-weight: normal;
            @media (max-width: 770px) {
                text-align: center; } } }
    #winnerList {
        @media (max-width: 770px) {
            text-align: center; }
        li {
            font-size: 1.4rem;
            padding: 0 75px;
            margin-bottom: 20px;
            font-family: $heading;
            font-weight: normal;
            color: $base-font-color;
            position: relative;
            display: inline-block;
            font-family: $copy;
            span.date {
                font-family: "Black And White Picture",sans-serif;
                font-size: 1.3rem;
                letter-spacing: 0.05rem;
                font-weight: normal;
                color: $main-color; }
            span.loesung {
                color: #5f8bab;
                font-weight: 800;
                font-size: 1rem; }
            span {
                color: #5f8bab;
                font-weight: 400;
                font-size: 1rem; }

            &:before {
                content: "";
                width: 70px;
                height: 50px;
                position: absolute;
                background: url(../img/li_before.png) center no-repeat;
                background-size: contain;
                left: 0;
                top: -5px; }
            &:after {
                content: "";
                width: 70px;
                height: 50px;
                position: absolute;
                background: url(../img/li_after.png) center no-repeat;
                background-size: contain;
                right: 0;
                top: -5px; } } } }
