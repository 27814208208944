#datenschutz {
    padding: 60px 10px;
    text-align: center;
    overflow: hidden;
    #geschenke {
        margin: 0 auto;
        max-width: 330px;
        display: block; }
    h2 {
        display: block;
        margin: 0 auto 40px;
        color: $main-color;
        font-family: $heading;
        font-weight: normal;
        line-height: 1; }
    p {
        max-width: 800px;
        margin: 0 auto; } }

